
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("codecrafters-frontend/templates/course-admin", function(){ return i("codecrafters-frontend/templates/course-admin.hbs");});
d("codecrafters-frontend/routes/course-admin", function(){ return i("codecrafters-frontend/routes/course-admin.ts");});
d("codecrafters-frontend/templates/course-admin/buildpack", function(){ return i("codecrafters-frontend/templates/course-admin/buildpack.hbs");});
d("codecrafters-frontend/controllers/course-admin/buildpack", function(){ return i("codecrafters-frontend/controllers/course-admin/buildpack.ts");});
d("codecrafters-frontend/routes/course-admin/buildpack", function(){ return i("codecrafters-frontend/routes/course-admin/buildpack.ts");});
d("codecrafters-frontend/templates/course-admin/buildpacks", function(){ return i("codecrafters-frontend/templates/course-admin/buildpacks.hbs");});
d("codecrafters-frontend/controllers/course-admin/buildpacks", function(){ return i("codecrafters-frontend/controllers/course-admin/buildpacks.ts");});
d("codecrafters-frontend/routes/course-admin/buildpacks", function(){ return i("codecrafters-frontend/routes/course-admin/buildpacks.ts");});
d("codecrafters-frontend/templates/course-admin/code-example-evaluator", function(){ return i("codecrafters-frontend/templates/course-admin/code-example-evaluator.hbs");});
d("codecrafters-frontend/controllers/course-admin/code-example-evaluator", function(){ return i("codecrafters-frontend/controllers/course-admin/code-example-evaluator.ts");});
d("codecrafters-frontend/routes/course-admin/code-example-evaluator", function(){ return i("codecrafters-frontend/routes/course-admin/code-example-evaluator.ts");});
d("codecrafters-frontend/templates/course-admin/code-example", function(){ return i("codecrafters-frontend/templates/course-admin/code-example.hbs");});
d("codecrafters-frontend/controllers/course-admin/code-example", function(){ return i("codecrafters-frontend/controllers/course-admin/code-example.ts");});
d("codecrafters-frontend/routes/course-admin/code-example", function(){ return i("codecrafters-frontend/routes/course-admin/code-example.ts");});
d("codecrafters-frontend/templates/course-admin/feedback", function(){ return i("codecrafters-frontend/templates/course-admin/feedback.hbs");});
d("codecrafters-frontend/controllers/course-admin/feedback", function(){ return i("codecrafters-frontend/controllers/course-admin/feedback.ts");});
d("codecrafters-frontend/routes/course-admin/feedback", function(){ return i("codecrafters-frontend/routes/course-admin/feedback.js");});
d("codecrafters-frontend/templates/course-admin/insights", function(){ return i("codecrafters-frontend/templates/course-admin/insights.hbs");});
d("codecrafters-frontend/controllers/course-admin/insights", function(){ return i("codecrafters-frontend/controllers/course-admin/insights.ts");});
d("codecrafters-frontend/routes/course-admin/insights", function(){ return i("codecrafters-frontend/routes/course-admin/insights.js");});
d("codecrafters-frontend/templates/course-admin/stage-insights-index", function(){ return i("codecrafters-frontend/templates/course-admin/stage-insights-index.hbs");});
d("codecrafters-frontend/controllers/course-admin/stage-insights-index", function(){ return i("codecrafters-frontend/controllers/course-admin/stage-insights-index.ts");});
d("codecrafters-frontend/routes/course-admin/stage-insights-index", function(){ return i("codecrafters-frontend/routes/course-admin/stage-insights-index.ts");});
d("codecrafters-frontend/templates/course-admin/stage-insights", function(){ return i("codecrafters-frontend/templates/course-admin/stage-insights.hbs");});
d("codecrafters-frontend/controllers/course-admin/stage-insights", function(){ return i("codecrafters-frontend/controllers/course-admin/stage-insights.ts");});
d("codecrafters-frontend/routes/course-admin/stage-insights", function(){ return i("codecrafters-frontend/routes/course-admin/stage-insights.ts");});
d("codecrafters-frontend/templates/course-admin/submissions", function(){ return i("codecrafters-frontend/templates/course-admin/submissions.hbs");});
d("codecrafters-frontend/controllers/course-admin/submissions", function(){ return i("codecrafters-frontend/controllers/course-admin/submissions.js");});
d("codecrafters-frontend/routes/course-admin/submissions", function(){ return i("codecrafters-frontend/routes/course-admin/submissions.js");});
d("codecrafters-frontend/templates/course-admin/tester-version", function(){ return i("codecrafters-frontend/templates/course-admin/tester-version.hbs");});
d("codecrafters-frontend/controllers/course-admin/tester-version", function(){ return i("codecrafters-frontend/controllers/course-admin/tester-version.ts");});
d("codecrafters-frontend/routes/course-admin/tester-version", function(){ return i("codecrafters-frontend/routes/course-admin/tester-version.ts");});
d("codecrafters-frontend/templates/course-admin/tester-versions", function(){ return i("codecrafters-frontend/templates/course-admin/tester-versions.hbs");});
d("codecrafters-frontend/controllers/course-admin/tester-versions", function(){ return i("codecrafters-frontend/controllers/course-admin/tester-versions.ts");});
d("codecrafters-frontend/routes/course-admin/tester-versions", function(){ return i("codecrafters-frontend/routes/course-admin/tester-versions.ts");});
d("codecrafters-frontend/templates/course-admin/update", function(){ return i("codecrafters-frontend/templates/course-admin/update.hbs");});
d("codecrafters-frontend/controllers/course-admin/update", function(){ return i("codecrafters-frontend/controllers/course-admin/update.ts");});
d("codecrafters-frontend/routes/course-admin/update", function(){ return i("codecrafters-frontend/routes/course-admin/update.ts");});
d("codecrafters-frontend/templates/course-admin/updates", function(){ return i("codecrafters-frontend/templates/course-admin/updates.hbs");});
d("codecrafters-frontend/controllers/course-admin/updates", function(){ return i("codecrafters-frontend/controllers/course-admin/updates.ts");});
d("codecrafters-frontend/routes/course-admin/updates", function(){ return i("codecrafters-frontend/routes/course-admin/updates.js");});
d("codecrafters-frontend/templates/course-admin/code-example-evaluators", function(){ return i("codecrafters-frontend/templates/course-admin/code-example-evaluators.hbs");});
d("codecrafters-frontend/routes/course-admin/code-example-evaluators", function(){ return i("codecrafters-frontend/routes/course-admin/code-example-evaluators.ts");});
d("codecrafters-frontend/templates/course-admin/loading", function(){ return i("codecrafters-frontend/templates/course-admin/loading.hbs");});
